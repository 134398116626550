<template>
  <div class="board-list">
    <div class="borders__container" v-if="boards.length > 0">
      <b-row>
        <b-col md="4" lg="3" v-for="board in boards" :key="board.id">
          <b-link :to="{ name: 'ShowBoard', params: { id: board.id } }">
            <div class="board__body mb-1">
              <img
                v-if="
                  ![undefined, null].includes(board.style) &&
                  Object.keys(board.style).length
                "
                :src="board.style.background_image[0].path"
                :alt="board.title"
                class="board__image"
              />
              <img
                v-else
                src="@/assets/images/pages/Rectangle 846.png"
                :alt="board.title"
                class="board__image"
              />
              <div class="board__content">
                <h4 class="board__name">
                  {{ board.title }}
                </h4>
              </div>
            </div>
          </b-link>
        </b-col>
      </b-row>
    </div>
    <div v-else class="no-data">
      {{ $t("noBoardsInThisCourse") }}
    </div>
  </div>
</template>

<script>
import card from "@/components/classrooms/boards/showCourses/boards/layout/card.vue";
import { BRow, BCol, BLink } from "bootstrap-vue";

export default {
  name: "BoardList",
  components: {
    card,
    BCol,
    BRow,
    BLink,
  },
  data() {
    return {
      boards: [],
    };
  },
  created() {
    this.$http
      .get(`/admin/boards?course_id=${this.$route.params.id}`)
      .then((res) => {
        if (res.status === 200) {
          this.boards = res.data.data;
        }
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
  },
};
</script>

<style lang="scss" src="./_boardList.scss"></style>
